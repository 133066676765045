<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select type="text"
                      filled
                      readonly
                      :items="services"
                      label="Послуга"
                      disabled
                      v-model="service_id"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="text"
                          filled
                          readonly
                          label="Серійний номер"
                          v-model="serial_number"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="serial_number ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="text"
                          filled
                          readonly
                          label="Місяць"
                          :value="formatMonth"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="month ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <date-component v-model="submission_date" req label="Дата подання"/>
          </v-col>
          <v-col cols="6">
            <v-select type="text"
                      filled
                      :items="addMethod"
                      label="Спосіб подання"
                      v-model="value_add_method"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="value_add_method ? '' : 'req-star'"
                      :disabled="personal"
                      color="grey"/>
            </v-col>
          <template v-if="!zone || zone === 1">
            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Початкові показники</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            readonly
                            label="Попередній"
                            v-model.number="prev_value_first"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Поточний"
                            v-model.number="current_value_first"
                            hide-details
                            color="grey"
                            @change="changeNextValue(1)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Різниця"
                            v-model.number="value_difference_first"
                            hide-details
                            color="grey"
                            @change="changeDifferenceValue(1)"
              />
            </v-col>
          </template>
          <template v-if="zone === 2">
            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Перша зона</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            readonly
                            label="Попередній"
                            v-model.number="prev_value_first"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Поточний"
                            v-model.number="current_value_first"
                            hide-details
                            color="grey"
                            @change="changeNextValue(1)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Різниця"
                            v-model.number="value_difference_first"
                            hide-details
                            color="grey"
                            @change="changeDifferenceValue(1)"
              />
            </v-col>

            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Друга зона</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            readonly
                            label="Попередній"
                            v-model="prev_value_second"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Поточний"
                            v-model.number="current_value_second"
                            hide-details
                            color="grey"
                            @change="changeNextValue(2)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Різниця"
                            v-model.number="value_difference_second"
                            hide-details
                            color="grey"
                            @change="changeDifferenceValue(2)"
              />
            </v-col>
          </template>
          <template v-if="zone === 3">
            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Перша зона</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            readonly
                            label="Попередній"
                            v-model="prev_value_first"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Поточний"
                            v-model.number="current_value_first"
                            hide-details
                            color="grey"
                            @change="changeNextValue(1)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Різниця"
                            v-model.number="value_difference_first"
                            hide-details
                            color="grey"
                            @change="changeDifferenceValue(1)"
              />
            </v-col>

            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Друга зона</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            readonly
                            label="Попередній"
                            v-model="prev_value_second"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Поточний"
                            v-model.number="current_value_second"
                            hide-details
                            color="grey"
                            @change="changeNextValue(2)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Різниця"
                            v-model.number="value_difference_second"
                            hide-details
                            color="grey"
                            @change="changeDifferenceValue(2)"
              />
            </v-col>

            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Третя зона</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            readonly
                            label="Попередній"
                            v-model="prev_value_third"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Поточний"
                            v-model.number="current_value_third"
                            hide-details
                            color="grey"
                            @change="changeNextValue(3)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text"
                            filled
                            label="Різниця"
                            v-model.number="value_difference_third"
                            hide-details
                            color="grey"
                            @change="changeDifferenceValue(3)"
              />
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_FLAT_COUNTER_READING, UPDATE_FLAT_COUNTER, REMOVE_FLAT_COUNTER
} from "@/store/actions/counter";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {addCounterValueMethod} from "@/utils/icons"
import {formatDate} from "@/filters";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_counter_modal_delete'

export default {
  name: "HWP_Modal_FlatCounterReading",
  mixins: [ModalComponentMixin],
  data() {
    return {
      addMethod: addCounterValueMethod,
      flat_id: this.item.flat_id,
      flat_counter_id: this.item.flat_counter_id || 0,
      service_id: this.item.service_id || 0,
      zone: this.item.zone || 0,
      serial_number: this.item.serial_number || '',
      month: this.item.month || null,
      submission_date: this.item.submission_date || null,
      value_add_method: this.item.value_add_method || null,
      prev_value_first: this.item.prev_value_first || 0,
      prev_value_second: this.item.prev_value_second || 0,
      prev_value_third: this.item.prev_value_third || 0,
      current_value_first: this.item.current_value_first || 0,
      current_value_second: this.item.current_value_second || 0,
      current_value_third: this.item.current_value_third || 0,
      value_difference_first: this.item.value_difference_first || 0,
      value_difference_second: this.item.value_difference_second || 0,
      value_difference_third: this.item.value_difference_third || 0,
      personal: this.item.personal || false,
    }
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      getAccountArchive: GET_ARCHIVE
    }),
    closeModal() {
      this.$emit('closeModal')
      this.personal = this.item.personal || false
      this.itemId = this.item.id
      this.flat_id = this.item.flat_id
      this.flat_counter_id = this.item.flat_counter_id || 0
      this.service_id = this.item.service_id || 0
      this.zone = this.item.zone || 0
      this.serial_number = this.item.serial_number || ''
      this.month = this.item.month || null
      this.submission_date = this.item.submission_date || null
      this.value_add_method = this.item.value_add_method || null
      this.prev_value_first = this.item.prev_value_first || 0
      this.prev_value_second = this.item.prev_value_second || 0
      this.prev_value_third = this.item.prev_value_third || 0
      this.current_value_first = this.item.current_value_first || 0
      this.current_value_second = this.item.current_value_second || 0
      this.current_value_third = this.item.current_value_third || 0
      this.value_difference_first = this.item.value_difference_first || 0
      this.value_difference_second = this.item.value_difference_second || 0
      this.value_difference_third = this.item.value_difference_third || 0

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення лічильника',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга, Дата подання, Спосіб подання - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      if (this.prev_value_first > 0 && this.current_value_first === 0 && this.value_difference_first === 0) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Вкажіть показник або різницю',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        flat_id: this.flat_id,
        flat_counter_id: this.flat_counter_id,
        service_id: this.service_id,
        month: this.month,
        submission_date: `${this.submission_date} 00:00:00`,
        value_add_method: this.value_add_method,
        prev_value_first: this.prev_value_first,
        prev_value_second: this.prev_value_second,
        prev_value_third: this.prev_value_third,
        current_value_first: this.current_value_first,
        current_value_second: this.current_value_second,
        current_value_third: this.current_value_third,
        value_difference_first: this.value_difference_first,
        value_difference_second: this.value_difference_second,
        value_difference_third: this.value_difference_third,
        personal: this.personal || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_FLAT_COUNTER_READING, payload)
            .then(el => {
              if (el) {
                const person_hash = this.$route.params.person_hash
                if (person_hash) {
                  this.fetchFlatServices(person_hash)
                  this.getAccountArchive(person_hash)
                }
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_FLAT_COUNTER, payload)
            .then(el => {
              if (el) {
                const person_hash = this.$route.params.person_hash
                if (person_hash) {
                  this.fetchFlatServices(person_hash)
                  this.getAccountArchive(person_hash)
                }
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.personal = payload.personal || false
              this.itemId = payload.id
              this.flat_id = payload.flat_id
              this.flat_counter_id = payload.flat_counter_id || 0
              this.service_id = payload.service_id || 0
              this.zone = payload.zone || 0
              this.serial_number = payload.serial_number || ''
              this.month = payload.month || null
              this.submission_date = payload.submission_date || null
              this.value_add_method = payload.value_add_method
                  ?  payload.value_add_method :
                  payload.personal
                      ? (addCounterValueMethod.find(i => i.value === 'BY_USER') || {}).value
                      : (addCounterValueMethod.find(i => i.value === 'MANUAL') || {}).value
              this.prev_value_first = payload.prev_value_first || 0
              this.prev_value_second = payload.prev_value_second || 0
              this.prev_value_third = payload.prev_value_third || 0
              this.current_value_first = payload.current_value_first || 0
              this.current_value_second = payload.current_value_second || 0
              this.current_value_third = payload.current_value_third || 0
              this.value_difference_first = payload.value_difference_first || 0
              this.value_difference_second = payload.value_difference_second || 0
              this.value_difference_third = payload.value_difference_third || 0
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_FLAT_COUNTER, this.itemId)
                    .then(ok => {
                      if (ok) {
                        const person_hash = this.$route.params.person_hash
                        if (person_hash) {
                          this.fetchFlatServices(person_hash)
                          this.getAccountArchive(person_hash)
                        }
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    changeNextValue(fieldNumber) {
      if (fieldNumber === 1) {
        if (this.current_value_first > 0) {
          this.value_difference_first = this.current_value_first - this.prev_value_first
        } else {
          this.value_difference_first = 0
        }
      }

      if (fieldNumber === 2) {
        if (this.current_value_second > 0) {
          this.value_difference_second = this.current_value_second - this.prev_value_second
        } else {
          this.value_difference_second = 0
        }
      }

      if (fieldNumber === 3) {
        if (this.current_value_third > 0) {
          this.value_difference_third = this.current_value_third - this.prev_value_third
        } else {
          this.value_difference_third = 0
        }
      }
    },
    changeDifferenceValue(fieldNumber) {
      if (fieldNumber === 1) {
        this.current_value_first = this.prev_value_first + this.value_difference_first
      }

      if (fieldNumber === 2) {
        this.current_value_second = this.prev_value_second + this.value_difference_second
      }

      if (fieldNumber === 3) {
        this.current_value_third = this.prev_value_third + this.value_difference_third
      }
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelect',
    }),
    formatMonth() {
      if (this.month) {
        return formatDate(this.month, 'MMMM YYYY')
      } else {
        return ""
      }

    }
  }
}
</script>
